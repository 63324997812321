<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-users-alt display-6 text-primary"></i></div>
              <div>
                <h4 class="fw-medium font-size-20 text-info mb-0"> {{ title }}</h4>
                <small class="d-block font-size-13 text-muted fw-normal">All Merchant List</small>
              </div>
             
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-12">
                <div class="d-md-flex">
                    <div class="d-flex">
                      <div class="app-search2 position-relative">
                        <input
                          type="search"
                          placeholder="Filter..."
                          class="form-control mb-3"
                          v-model.trim="searchKey"
                          autocomplete="no"
                          @keyup="filterByResult()"
                        />
                        <span class="uil-search text-info"></span>
                      </div>
                      <button
                        class="btn btn-light mb-3 ms-2 text-nowrap"
                        type="button"
                        @click="clear()"
                      >
                        <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="ms-auto mb-3 mb-md-0 text-end">
                    <button type="button" class="btn btn-info fw-medium rounded" @click="$refs['modal-addmerchant'].show()"><i class="uil uil-plus-circle me-1"></i> Add Merchant Account</button>
                  </div>
                  
                </div>
              </div>
              <div class="col-12 col-lg-auto d-none">
                <div class="row">
                  <div class="col-6 pe-1 d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="col-6 ps-1 d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
                <div class="table-responsive text-nowrap font-size-14">
                  <table class="table mb-3 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0}">
                    <thead class="text-uppercase">
                      <tr>
                        <th>Name/Username</th>
                        <th>Merchant Code</th>
                        <th>Merchant Key</th>
                        <th>Website Domain</th>
                        <th>Payment Url</th>
                        <th>Return Url</th>
                        <th class="text-end" width="120">Active</th>
                        <th class="text-end" width="120">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!filterByResult().length && !loading && searchKey">
                        <td colspan="8" height="200" class="text-center text-muted">
                          <i class="uil uil-usd-circle me-1"></i>  Sorry, we couldn't
                          find any results of "{{ searchKey }}".
                        </td>
                      </tr>
                      <tr v-if="!returnData.length && !loading">
                        <td colspan="8" height="200" class="text-center text-muted">
                          <i class="uil uil-usd-circle me-1"></i> No Data Available
                        </td>
                      </tr>
                      <tr v-if="loading">
                        <td colspan="8" class="text-center text-muted" height="300">
                          <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </td>
                      </tr>
                      <tr v-for="(value, index) in filterByResult()" :key="index">
                        <td>
                         <strong class="fw-medium text-info">{{ value.merchant_name  }}</strong><br>Username: {{ value.merchant_username  }} 
                        </td>
                        <td>
                          {{ value.merchant_code }}
                        </td>
                        <td>
                          {{ value.merchant_API_key }}
                        </td>
                        <td>
                          {{ value.merchant_webiste_url }}
                        </td>
                        <td>
                          {{ value.merchant_payment_url }}
                        </td>
                        <td>
                          {{ value.merchant_return_url }}
                        </td>
                        <td class="text-end">
                          <div class="form-check form-switch form-switch-md float-end">
                            <input class="form-check-input" type="checkbox" @change="updateUPI(value)" role="switch" v-model="value.active" true-value="1" false-value="0" :disabled="submitted">
                          </div>
                        </td>
                        <td class="text-end">
                          <button type="button" class="btn btn-outline-danger btn-sm rounded" @click="removeUPI(value)"><i class="uil uil-trash-alt"></i> Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" v-if="returnData.length">
                  <div class="col mt-3 d-none">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          hide-ellipsis
                          @change="handlePageChange"
                          v-model="currentPage"
                          :per-page="perPage"
                          :total-rows="totalData"
                        >
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
            
      </div>
    </div>
    <Lottie :path="'animate/loading-168.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <b-modal
      @hidden="resetModal"
      ref="modal-addmerchant"
      centered
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
    >
      <div class="row align-items-center">
        <div class="col-12 text-white" >
          <h5 class="text-info mb-3">Add Merchant Account</h5>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <label>Username</label>
            <input
              v-model.trim="formData.username"
              type="text"
              class="form-control"
              placeholder="Username"
              name="username"
              :class="{
                'is-invalid':
                modalSubmit && $v.formData.username.$error,
              }"
            />
            <div
              v-if="modalSubmit && $v.formData.username.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.formData.username.required"
                >Username is required.</span
              >
              <span v-else-if="!$v.formData.username.validUsername"
                >Username must contain only lowercase alphabets and numbers.</span
              >
            </div>
          </div>
          <div class="mb-3">
            <label>Password</label>
            <div class="position-relative">
              <input
                v-model="formData.password"
                :type="passwordFieldType"
                name="password"
                class="form-control"
                :class="{
                  'is-invalid':
                  modalSubmit && $v.formData.password.$error,
                }"
                placeholder="Password"
              />
              <div
                v-if="modalSubmit && $v.formData.password.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.formData.password.required"
                  >Password is required.</span
                >
                <span v-if="!$v.formData.password.minLength"
                  >Password must be at least 8 characters.</span
                >

                <span v-else-if="!$v.formData.password.alpha">
                  Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                >
              </div>
              <div
                id="eye"
                class="user-select-none"
                @click="switchVisibility()"
              >
                <i
                  :class="classicon"
                  id="eyetype"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label>Confirm Password</label>
            <div class="position-relative">
            <input
              v-model="formData.confirmPassword"
              :type="passwordFieldType2"
              name="confirmPassword"
              class="form-control"
              :class="{
                'is-invalid':
                modalSubmit && $v.formData.confirmPassword.$error,
              }"
              placeholder="Confirm Password"
            />
            <div
              v-if="
                modalSubmit && $v.formData.confirmPassword.$error
              "
              class="invalid-feedback"
            >
              <span v-if="!$v.formData.confirmPassword.required"
                >Confirm password is required.</span
              >
              <span
                v-else-if="
                  !$v.formData.confirmPassword.sameAsPassword
                "
                >Confirm password does not match the new
                password.</span
              >
            </div>
            <div
              id="eye2"
              class="user-select-none"
              @click="switchVisibility2()"
            >
              <i
                :class="classicon2"
                id="eyetype2"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          </div> 
          <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
          <hr>
          <div class="mb-3">
            <label>Name</label>
            <input
              v-model="formData.name"
              type="text"
              class="form-control"
              placeholder="Name"
              name="name"
              :class="{
                'is-invalid':
                modalSubmit && $v.formData.name.$error,
              }"
            />
            <div
              v-if="modalSubmit && $v.formData.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.formData.name.required"
                >Name is required.</span
              >
            </div>
          </div>
          <div class="mb-3">
            <label>Website Url</label>
            <input
              v-model="formData.websiteUrl"
              type="text"
              class="form-control"
              placeholder="Website Url"
              name="website"
              :class="{
                'is-invalid':
                modalSubmit && $v.formData.websiteUrl.$error,
              }"
            />
            <div
              v-if="modalSubmit && $v.formData.websiteUrl.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.formData.websiteUrl.required"
                >Website url is required.</span
              >
              <span v-else-if="!$v.formData.websiteUrl.validWebsiteUrl"
                >Please enter a valid website url.</span
              >
              
            </div>
          </div>
          <div class="mb-3">
            <label>Payment Url</label>
            <input
              v-model="formData.paymentUrl"
              type="text"
              class="form-control"
              placeholder="Payment Url"
              name="paymentUrl"
              :class="{
                'is-invalid':
                modalSubmit && $v.formData.paymentUrl.$error,
              }"
            />
            <div
              v-if="modalSubmit && $v.formData.paymentUrl.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.formData.paymentUrl.required"
                >Payment url is required.</span
              >
              <span v-else-if="!$v.formData.paymentUrl.validWebsiteUrl"
                >Please enter a valid payment url.</span
              >
              
            </div>
          </div>
          <div class="mb-3">
            <label>Return Url</label>
            <input
              v-model="formData.returnUrl"
              type="text"
              class="form-control"
              placeholder="Return Url"
              name="returnUrl"
              :class="{
                'is-invalid':
                modalSubmit && $v.formData.returnUrl.$error,
              }"
            />
            <div
              v-if="modalSubmit && $v.formData.returnUrl.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.formData.returnUrl.required"
                >Return url is required.</span
              >
              <span v-else-if="!$v.formData.returnUrl.validWebsiteUrl"
                >Please enter a valid return url.</span
              >
              
            </div>
          </div>
         
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-addmerchant'].hide()">Cancel</button>
            
            <button type="button" class="btn fw-medium btn-info" @click="addMerchantRequest" :disabled="modalLoading">
              <span v-if="modalLoading"> Adding...</span>
              <span v-else>  Add Merchant Account </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
        
      </div>
    </b-modal>

  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
const validWebsiteUrl = (value) => {
  console.log(value);
  const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
  return regex.test(value);
}
const validUsername = (value) => {
  const regex = /^[a-z0-9]+$/;
  return regex.test(value);
};

/**
 * Starter page
 */
 const PageTitle = "Merchant"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      search_value:'',
      searchKey:'',
      searchKey2:'',
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "OneTouch Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      passwordFieldType_current: "password",
      classicon_current: "uil uil-eye-slash",
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      
      currentPage:1,
      perPage: 20,
      totalData: 0,
      submitted:false,
      modalSubmit:false,
      modalLoading:false,
      loading:false,
      loading2:false,
      buttonLoading:[false,false,false],
      returnData: [],
      formData: {
        username:"",
        name:"",
        apiKey: "",
        paymentUrl: "",
        returnUrl: "",
        websiteUrl: "",
        password: "",
        confirmPassword: "",
        otp: "",
      }
    };
  },
  validations: {
    formData: {
      username: {
        required,
        validUsername
      },
      name: {
        required,
      },
      apiKey: {
        required,
      },
      paymentUrl: {
        required,
        validWebsiteUrl
      },
      returnUrl: {
        required,
        validWebsiteUrl
      },
      websiteUrl: {
        required,
        validWebsiteUrl
      },
      password: {
        required,
        alpha,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    console.log( this.$refs.commonFunc.getPhone())
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    await this.getData();
    
  }, 
  created(){
   
  },
  methods:{
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      //this.getData(this.pageNum, this.search_value);
      //this.exportData(1)
    },
    convertedTransactionLimit(value) {
      // Assuming this.transactionLimit is defined in your component's data
      return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
    },
    inputNumberOnly(event) {
      var numericValue = event.target.value.replace(/[^0-9]/g, '');
      this.addBank.bank_account_no = numericValue;
      if (event.target.value !== numericValue) {
          return
      }
    },
    inputAmount(event) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this.addUPI.transaction_limit = numericValue;
      this.addBank.transaction_limit = numericValue;
      if (event.target.value !== numericValue) {
          return
      }
    },
    updateAmount() {
      const numericValue = this.addUPI.transaction_limit.replace(/[^0-9.]/g, '');
      if (numericValue !== '') {
          const amountNumber = parseInt(numericValue);
          if (!isNaN(amountNumber)) {
              this.addUPI.transaction_limit = amountNumber.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  useGrouping: true
              });
              this.addBank.transaction_limit = amountNumber.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  useGrouping: true
              });
          } else {
            this.addUPI.transaction_limit = '10,000';
            this.addBank.transaction_limit = '10,000';
          }
      } else {
        this.addUPI.transaction_limit = '10,000';
        this.addBank.transaction_limit = '10,000';
      }
    },
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.formData={
        username:"",
        name:"",
        paymentUrl: "",
        returnUrl: "",
        websiteUrl: "",
        password: "",
        confirmPassword: "",
      }
    },
    async getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            
              this.returnData = [
                {
                id: '1',
                merchant_username: 'merchant',
                merchant_name: 'Hugo',
                merchant_code: 'M00001',
                merchant_API_key: '4f8d3a2e-1b2c-4e5f-8d9a-0b1c2d3e4f5g',
                merchant_webiste_url: 'https://1xbet.com',
                merchant_payment_url: 'https://1xbet.com/payment',
                merchant_return_url: 'https://1xbet.com/callback',
                active:1
                },
                {
                id: '2',
                merchant_username: 'walletdemo',
                merchant_name: 'Wallet Demo',
                merchant_code: 'M00002',
                merchant_API_key: '3f57d8d2-920b-4c6c-9ef8-9d7e3d126b18',
                merchant_webiste_url: 'https://wallet.aisoftware.my',
                merchant_payment_url: 'https://wallet.aisoftware.my/payment',
                merchant_return_url: 'https://wallet.aisoftware.my/callback',
                active:1
                },
                {
                id: '3',
                merchant_username: 'veryheng',
                merchant_name: 'Very Heng Sdn Bhd',
                merchant_code: 'M00003',
                merchant_API_key: '9f57d8d2-120b-2c6c-9ef8-3d7e3d126b18',
                merchant_webiste_url: 'https://demo.aisoftware.my',
                merchant_payment_url: 'https://demo.aisoftware.my/payment',
                merchant_return_url: 'https://demo.aisoftware.my/callback',
                active:0
                }
              ]
              this.reload()
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
    switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
    
    updateUPI(value){
      this.$Progress.start();
      this.submitted = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("username",  this.accessUsername);
      bodyFormData.append("id", value.id);
      bodyFormData.append("active", value.active);
      axios({
          method: "post",
          url: appConfig.DemoAPI,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          this.returnData = []
          if (resData.status == 200) {
            console.log(resData)
            this.getData();
          } 
          else if (resData.status == 401){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.submitted = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.submitted = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    
    removeUPI(value) {
      Swal.fire(
        {
        icon: 'info',
        title: 'Confirm Merchant Removal',
        html: `Are you certain you want to remove ${value.merchant_name} (${value.merchant_username}). `,
        confirmButtonColor: '#222',
        confirmButtonText: "Yes, Remove",
        showCancelButton: true,
        cancelButtonColor: '#aaa',
        reverseButtons: true
      }).then((response) => {
          if (response.isConfirmed){
              this.$Progress.start();
              this.submitted = true
              var bodyFormData = new FormData();
              bodyFormData.append("accessToken", this.accessToken);
              bodyFormData.append("username",  this.accessUsername);
              bodyFormData.append("id", value);
              axios({
                  method: "post",
                  url: appConfig.DemoAPI,
                  data: bodyFormData,
                  headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response)=>{
                  this.returnData = []
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    this.getData();
                  } 
                  else if (resData.status == 401){
                    Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                      this.$refs.commonFunc.clearData()
                      this.$router.push({
                          path: "/login",
                    });
                  }
                  else {
                      Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                  }
                  this.submitted = false;
                  this.$Progress.finish();
                })
                .catch((error)=> {
                    this.submitted = false;
                    this.$Progress.finish();
                    //Swal.fire("Error", error, "error");
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${error}.`,
                      confirmButtonColor: '#222',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
              });
          }
      })
    },
    addMerchantRequest(){
      this.modalSubmit = true
      this.$v.formData.$touch();
      if (this.$v.formData.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        // bodyFormData.append("upiID", this.addUPI.upi_id);
        // bodyFormData.append("upiName", this.addUPI.upi_name);
        // bodyFormData.append("type", this.addUPI.upi_type);
        // bodyFormData.append("transactionLimit", this.addUPI.transaction_limit.replaceAll(",",""));

        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'New UPI Account Added',
                html: `UPI Account has been successfully added!`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-addmerchant'].hide()
                this.getData();
              })
              
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    clear(){
      this.searchKey=""
      this.searchKey2=""
    },
    filterByResult() {
      return this.returnData.filter((e) => {
          return (
          e.merchant_username.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.merchant_name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.merchant_code.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.merchant_API_key.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.merchant_webiste_url.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.merchant_payment_url.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.merchant_return_url.toLowerCase().includes(this.searchKey.toLowerCase())
          );
      })
    },
    
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.formData.apiID = data.apiID;
          this.formData.apiHash = data.apiHash;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>
<style scoped>
#eye_current,
#eye,
#eye2,
#eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
}
</style>